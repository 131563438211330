<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/salary`"
                title="Salary"
                :columns="columns"
                routerpath="/hr/addsalary"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      title: "Widget",
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Salary" : "Add Salary"}`,
        submitRouterPath: "/hr/salary",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/team`,
        inputFields: [],
        getEditValue: `${baseApi}/getsalaryById`,

      },
      viewFields: [
        {
          label: 'Date',
          field: 'date',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Employee Name',
          field: 'employee_id',
          type: 'dropdown',
          responseValue:'fullname',
          class: "col-md-4",
        },
        {
          label: 'Basic + DA',
          field: 'basicda',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'HRA',
          field: 'hra',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Conveyance',
          field: 'conveyance',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Medical Allowance',
          field: 'medicalallowance',
          type: 'text',
          class: "col-md-4",
        },{
          label: 'Education Allowance',
          field: 'educationallowance',
          type: 'text',
          class: "col-md-4",
        },{
          label: 'City Compensatory Allowance(CCA)',
          field: 'cca',
          type: 'text',
          class: "col-md-4",
        },{
          label: 'Leave Travel Allowance (LTA)',
          field: 'lta',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Conveyance',
          field: 'conveyance',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Special Allowance',
          field: 'specialallowance',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Gross',
          field: 'gross',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'PT',
          field: 'pt',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'PF',
          field: 'pf',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'ESIC',
          field: 'esic',
          type: 'text',
          class: "col-md-4",
        },
        {
          label: 'Effective Date',
          field: 'effectivedate',
          type: 'text',
          class: "col-md-4",
        },

      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Employee Name",
          field: "employee_id",
          url:'getUsers',
          responseValue:'fullname',
          type:'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Basic + DA",
          field: "basicda",
          filterOptions: {
            enabled: true,
            placeholder: "Search Basic + DA",
          },
        },
        {
          label: "HRA",
          field: "hra",
          filterOptions: {
            enabled: true,
            placeholder: "Search HRA",
          },
        },
        {
          label: "Gross",
          field: "gross",
          filterOptions: {
            enabled: true,
            placeholder: "Search Gross",
          },
        },
        {
          label: "PT",
          field: "pt",
          filterOptions: {
            enabled: true,
            placeholder: "Search PT",
          },
        },
        {
          label: "PF",
          field: "pf",
          filterOptions: {
            enabled: true,
            placeholder: "Search PF",
          },
        },
        {
          label: "ESIC",
          field: "esic",
          filterOptions: {
            enabled: true,
            placeholder: "Search ESIC",
          },
        },
        {
          label: "Effective Date",
          field: "effectivedate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Effective Date",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url:'getUsers',
          responseValue:'fullname',
          type:'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url:'getUsers',
          responseValue:'fullname',
          type:'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Salary") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
